const getStatusColor = (status: string, theme: any): string => {
  let color;
  switch (status.toLowerCase()) {
    case 'queued': {
      color = theme.tag.queued;
      break;
    }
    case 'pending_approval': {
      color = theme.tag.pendingApproval;
      break;
    }
    case 'digitized': {
      color = theme.tag.digitized;
      break;
    }
    case 'processed': {
      color = theme.tag.processed;
      break;
    }
    case 'approved': {
      color = theme.tag.approved;
      break;
    }
    case 'not_processed': {
      color = theme.tag.notProcessed;
      break;
    }
    case 'partially_processed': {
      color = theme.tag.partiallyProcessed;
      break;
    }
    case 'in_third_party_process': {
      color = theme.tag.tms;
      break;
    }
    default: {
      color = theme.tag.noDocuments;
    }
  }

  return color;
};

export default getStatusColor;
