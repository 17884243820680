import { ApolloError, useLazyQuery } from '@apollo/client';

import { wickedAppVar, wickedApiVar } from 'src/apollo/reactiveVars';

import { GET_COUNT_BY_STATUS } from '../apollo/queries';

const useStatusCount = (): any => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Get Status Count Query
  const [getCount, { data: statusData, loading, refetch }] = useLazyQuery<{
    getCountByStatusOfJobs: string;
  }>(GET_COUNT_BY_STATUS, {
    variables: {
      wickedApp,
      wickedApi,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  let status;
  loading || !statusData
    ? (status = null)
    : (status = JSON.parse(statusData.getCountByStatusOfJobs));

  return {
    status,
    loading,
    getCount,
    refetch,
  };
};

export default useStatusCount;
