import React from 'react';

import styled from '@emotion/styled';
import { Dropdown, Menu, Input } from 'antd';
import { IoClose } from 'react-icons/io5';
import { FixedSizeList as List } from 'react-window';

import ArrowDown from '@assets/Icons/ArrowDown.svg';
import Tooltip from '@components/elements/Tooltip';

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.color.gray900};
  svg {
    height: 1em;
    fill: currentColor;
    flex: none;
  }

  .close-icon {
    height: 1rem;
    width: 1rem;
    fill: currentColor;
    cursor: pointer;
  }

  span {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      width: 100%;
      z-index: 1;
    }

    svg {
      z-index: 2;
      height: 1.3rem;
      width: 1.3rem;
      margin-left: -2.3em;
      margin-right: 1rem;
      cursor: pointer;
    }
  }
`;

const StyledDropdownInput = styled(Input)`
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => props.theme.color.accent700};
  padding: 0 0.5rem;
  &:disabled {
    color: ${(props) => props.theme.color.accent700};
    cursor: unset;
  }
`;

const StyledDropdownMenu = styled(Menu)`
  padding-top: 0;
  padding-bottom: 0;
  max-height: 300px;
  background-color: ${(props) => props.theme.color.primary900};
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.color.primary50};
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.primary50}99;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.color.primary50};
  }
`;

const StyledDropdownMenuItem = styled(Menu.Item)`
  background-color: ${(props) => props.theme.color.primary900} !important;
  padding: 0px;
  &:hover {
    background: none;
  }

  .ant-dropdown-menu-title-content {
    width: 100%;
    height: 100%;
  }
`;

const StyledList = styled(List)`
  width: 100%;
  overflow-x: hidden !important;
  overflow-y: auto !important;

  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.color.primary50};
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.primary50}99;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.color.primary50};
  }
`;

const isTextOverflowing = (inputRef: any): boolean => {
  if (inputRef && inputRef.current) {
    const inputField = inputRef.current.input;
    return inputField.clientWidth < inputField.scrollWidth;
  }
  return false;
};

interface Props {
  value: string;
  onInputChange?: (value: string) => void;
  options?: any[];
  optionsRender?: (option: any) => React.ReactNode;
  onOptionSelected?: (option: any) => void;
  selectOnly?: boolean;
  placeholder?: string;
  formContainerRef: any;
  optionHeight?: number;
  readOnly?: boolean;
  isStrict?: boolean;
  isNumber?: boolean;
  isClearable?: boolean;
}

const FormInput = (props: Props): JSX.Element => {
  const itemSize = props.optionHeight ?? 35;
  const maxListHeight = 300;
  const allItemsHeight = props.options ? props.options.length * itemSize : 0;

  const listHeight =
    allItemsHeight > maxListHeight ? maxListHeight : allItemsHeight;

  const OptionsMenu = props.options ? (
    <StyledDropdownMenu>
      <StyledList
        width={'100%'}
        height={listHeight}
        itemData={props.options}
        itemCount={props.options.length}
        itemSize={itemSize}
      >
        {({ data, index, style }: { data: any; index: number; style: any }) => {
          const option = data[index];
          return (
            <StyledDropdownMenuItem
              key={option?.name}
              onClick={() =>
                props.onOptionSelected && props.onOptionSelected(option)
              }
              style={style}
            >
              {props.optionsRender ? props.optionsRender(option) : option}
            </StyledDropdownMenuItem>
          );
        }}
      </StyledList>
    </StyledDropdownMenu>
  ) : (
    <></>
  );

  // Input ref for checking text-overflow
  const inputRef = React.useRef<any>(null);

  const handleOnBlurForDropdown = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      props.isStrict &&
      !e.relatedTarget?.classList.contains('ant-dropdown-menu-item') &&
      !props.readOnly
    ) {
      let hasValidValue = false;
      if (props.options) {
        for (const option of props.options) {
          if (option.shortID === props.value) {
            hasValidValue = true;
            break;
          }
        }
      }

      !hasValidValue && props.onInputChange && props.onInputChange('');
    }
  };

  return (
    <StyledDropdown
      overlay={OptionsMenu}
      trigger={['click']}
      getPopupContainer={() => props.formContainerRef.current}
      // Disable dropdown menu when ReadOnly or if no Options are provided
      disabled={props.readOnly || !(props.options && props.options.length > 0)}
    >
      <div>
        <Tooltip
          title={isTextOverflowing(inputRef) && props.value}
          trigger={props.value === '' ? [] : ['hover']}
        >
          <StyledDropdownInput
            bordered={false}
            value={props.value}
            maxLength={200}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.onInputChange && props.onInputChange(e.target.value)
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleOnBlurForDropdown(e);

              // Validate number value
              if (props.isNumber && props.value) {
                props.onInputChange &&
                  (props.value
                    ? props.onInputChange(
                        Number.isNaN(
                          Number.parseFloat(props.value.replaceAll(/[,-]/g, ''))
                        )
                          ? ''
                          : Number.parseFloat(
                              props.value.replaceAll(/[,-]/g, '')
                            ).toString()
                      )
                    : props.onInputChange(''));
              }
            }}
            onKeyDown={(e) => {
              // Accessibility
              if (e.code === 'Enter') {
                // Opens the dropdown
                e.currentTarget.click();
              }
            }}
            placeholder={props.placeholder || 'Not Detected'}
            readOnly={props.selectOnly || props.readOnly}
            ref={inputRef}
          />
        </Tooltip>
        {props.readOnly !== true &&
          props.isClearable &&
          props.value?.length > 0 && (
            <IoClose
              onClick={() => props.onInputChange && props.onInputChange('')}
              className="close-icon"
            />
          )}
        {props.readOnly !== true &&
          Array.isArray(props.options) &&
          props.options.length > 0 && <ArrowDown className="dropdown-button" />}
      </div>
    </StyledDropdown>
  );
};

FormInput.defaultProps = {
  readonly: false,
  isStrict: false,
};

export default FormInput;
