import React from 'react';

import { useSubscription } from '@apollo/client';

import eventBus from '@utils/eventBus';
import { wickedApiVar, wickedAppVar } from 'src/apollo/reactiveVars';
import { Events } from 'src/global/constants';

import { DOCUMENT_UPLOAD_RESPONSE } from '../apollo/queries';
import { currentJobVar, updatingJobsVar } from '../apollo/reactiveVars';

import type { DocumentUploadResponse } from '@common/types';

const useDocumentUploadResponse = ({
  onResponse,
}: {
  onResponse: (data: DocumentUploadResponse) => void;
}): any => {
  // Get Wicked App
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Query
  const { loading, error } = useSubscription(DOCUMENT_UPLOAD_RESPONSE, {
    variables: {
      wickedApp,
      wickedApi,
    },
    fetchPolicy: 'network-only',
    onSubscriptionData: ({ subscriptionData }: any) => {
      const data = subscriptionData.data
        .documentUploadedResponse as DocumentUploadResponse;
      console.log('SUBSCRIPTION RESPONSE:', subscriptionData);

      if ((data.error?.message || data.type === 'digitized') && data.jobID) {
        updatingJobsVar(updatingJobsVar().filter((id) => id !== data.jobID));
      }

      if (data.type !== 'acceptance' || data.error?.message) {
        // Dispatch updateJobCount event to the eventBus
        !currentJobVar() && eventBus.dispatch(Events.updateJobCount, {});

        // Return response
        onResponse(data);
      }

      // Update document Image in review page
      if (data.jobID === currentJobVar()?.jobID) {
        eventBus.dispatch(Events.documentImageRefresh, {});
      }
    },
  });

  React.useEffect(() => {
    if (error) {
      console.error('Subscription Erorr', error);
    }
  }, [error]);

  return { loading, error };
};

export default useDocumentUploadResponse;
