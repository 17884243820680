import {
  currentFormConflictsVar,
  currentFormDataVar,
} from '../../apollo/reactiveVars';

import type { FormField } from '../../types';

const changeFormData = (
  field: FormField,
  value: string,
  others: Record<string, any> = {}
) => {
  // Get Current Form Data
  const formData = { ...currentFormDataVar() };
  // Check if Data exists
  if (formData[field.name] === undefined) {
    formData[field.name] = [];
  }
  // Prepare New Data with source User
  let newData = formData[field.name].find(
    (data) => data.text === (value ?? '').trim()
  );
  newData =
    newData === undefined
      ? {
          page: [[]],
          docID: [],
          text: (value ?? '').trim(),
          source: 'user',
          ...others,
        }
      : { ...newData, source: 'user', ...others };
  // Check if previous user data exists
  const userDataIndex =
    formData[field.name].findIndex((data) => data.source === 'user') || -1;
  if (userDataIndex === -1) {
    // Add new User Data
    formData[field.name].push(newData);
  } else {
    // Update Previous User Data
    formData[field.name][userDataIndex] = newData;
  }
  // Resolve Conflicts
  if (currentFormConflictsVar().includes(field.name)) {
    currentFormConflictsVar(
      currentFormConflictsVar().filter((name) => name !== field.name)
    );
  }
  // Update Form Data
  currentFormDataVar({ ...formData });
};

export default changeFormData;
