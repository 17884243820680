import { makeVar } from '@apollo/client';

import { initialFilters } from '../components/home/utils';

import type {
  Job,
  Document,
  FormFieldGroup,
  FormData,
  DocumentMetaData,
  AppConfig,
  HomePageFilters,
  JobType,
  EnumValuesData,
} from '../types';

export const appConfigVar = makeVar<AppConfig | null>(null);
export const currentJobVar = makeVar<Job | null>(null);
export const currentJobTypeVar = makeVar<JobType | null>(null);
export const currentJobDataRevVar = makeVar<number>(0);
export const currentDocumentVar = makeVar<Document | null>(null);
export const currentFormFieldGroupsVar = makeVar<FormFieldGroup[]>([]);
export const currentFormDataVar = makeVar<FormData>({});
export const currentFormOldDataVar = makeVar<FormData>({});
export const currentFormConflictsVar = makeVar<string[]>([]);
export const currentFormErrorsVar = makeVar<Record<string, any>>({});
export const currentDocumentMetaDataVar = makeVar<
  Record<string, DocumentMetaData>
>({});
export const currentLockedDocumentsVar = makeVar<string[]>([]);
export const imageGalleryVar = makeVar<{
  image: string;
  direction: string;
} | null>(null);
export const updatingJobsVar = makeVar<string[]>([]);
export const hasUnsavedChangesVar = makeVar<Record<string, boolean>>({
  formData: false,
  jobType: false,
});
export const enumValuesVar = makeVar<EnumValuesData | null>(null);

// To store the entire list of documents to be splitted
// export const splitDocumentsListVar = makeVar<any>([]);

// 'originalDocIndex-splittedDocIndex-pageIndex'
export const selectedPagePathVar = makeVar<string>('0-0-0');

// to store home page filters
export const homePageFiltersVar = makeVar<HomePageFilters>(initialFilters);
