import { css, Global } from '@emotion/react';

export const AntdGlobalStyles = (): JSX.Element => {
  // const theme = useTheme();
  return (
    <Global
      styles={css`
        .red {
          color: red;
        }

        .ant-dropdown .ant-table-filter-dropdown {
          overflow: unset !important;
          background-color: transparent !important;
          .ant-input {
            &::placeholder {
              color: #b6b9bc !important;
            }
          }
        }
        // TODO - show image gallery controls only on hover
        // .image-gallery-image-container :hover {
        //   // display: none;
        //   .gallery-bottom-controls {
        //     display: block;
        //   }
        // }
      `}
    />
  );
};
