/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';

import styled from '@emotion/styled';
import { clsx } from 'clsx';
import { useDebouncedCallback } from 'use-debounce';

import {
  currentDocumentMetaDataVar,
  currentFormConflictsVar,
} from '@common/apollo/reactiveVars';
import { changeFormData } from '@common/utils';
import { FormInputOption } from '@components/elements/FormInput';
import Tooltip from '@components/elements/Tooltip';
import { isTextOverflowing } from '@utils/commonJs';

import type {
  DocumentMetaData,
  FieldData,
  FormField,
  FormFieldOption,
} from '@common/types';

const StyledDocumentType = styled.div`
  text-transform: uppercase;
`;

// const StyledDocumentNumber = styled.span`
//   margin-left: 5px;
//   font-size: 8px;
//   padding: 5px;
//   background: ${(props) => props.theme.color.accent700};
//   color: ${(props) => props.theme.color.gray50};;
// `;

const StyledDocuementName = styled.div`
  color: ${(props) => props.theme.color.gray800};
`;
const StyledDocumentValue = styled.div`
  color: ${(props) => props.theme.color.gray500};
`;

const useFormInput = ({
  field,
  data,
}: {
  field: FormField;
  data: FieldData[];
}) => {
  // Value State
  const [value, setValue] = React.useState('');

  // Update Value on Data Change
  React.useEffect(() => {
    if (data.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setValue(data.at(-1).text);
    }
  }, [data]);

  // Change Data Callback
  const changeData = useDebouncedCallback(
    (value: string, others: Record<string, any> = {}) => {
      changeFormData(field, value, others);
    },
    300
  );

  // Handle Change
  const handleOnChange = (value: any, others: Record<string, any> = {}) => {
    value?.shortID && (value = value.shortID);
    value?.text && (value = value.text);

    // value = value?.shortID ? value.shortID : value?.text ? value.text : value;
    setValue(value);
    changeData(value, others);
  };

  // Options
  const options: any[] = currentFormConflictsVar().includes(field.name)
    ? [...data]
    : [];

  if (!currentFormConflictsVar().includes(field.name)) {
    options.push(...field.options);
  }

  const formInputOptionRef = React.useRef(null);
  const formInputConflictOptionRef = React.useRef(null);

  const getTextOption = (text: string, active: boolean) => (
    <FormInputOption className={clsx({ active })}>
      <Tooltip title={isTextOverflowing(formInputOptionRef) && text}>
        <div ref={formInputOptionRef}>{text}</div>
      </Tooltip>
    </FormInputOption>
  );

  const getConflictOption = (
    option: FormFieldOption,
    documentMetaData: DocumentMetaData,
    active: boolean
  ) => (
    <FormInputOption className={clsx({ active })}>
      <StyledDocumentType style={{ color: documentMetaData?.color || '#fff' }}>
        {documentMetaData?.docType}
        {/* {option.docID.length > 1 && (
          <StyledDocumentNumber>+{option.docID.length - 1}</StyledDocumentNumber>
        )} */}
      </StyledDocumentType>
      <StyledDocuementName>{documentMetaData?.docName}</StyledDocuementName>
      <Tooltip title={isTextOverflowing(option.text) && option.text}>
        <div ref={formInputConflictOptionRef}>{option.text}</div>
      </Tooltip>
      <StyledDocumentValue></StyledDocumentValue>
    </FormInputOption>
  );

  // Option Render
  const optionsRender = (option: FormFieldOption) => {
    if (option.docID && Array.isArray(option.docID)) {
      const active = option.text === value;
      const documentMetaData = currentDocumentMetaDataVar()[option.docID[0]];
      return getConflictOption(option, documentMetaData, active);
    } else if (option.shortID && option.name) {
      const active = option.shortID === value;
      const text =
        option.name === option.shortID
          ? option.shortID
          : `${option.shortID} - ${option.name}`;
      return getTextOption(text, active);
    }
  };

  return {
    value,
    setValue,
    handleOnChange,
    options,
    optionsRender,
  };
};

export default useFormInput;
