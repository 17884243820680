/**
 * @param hex - hexcode of color
 * @param alpha - opacity value
 * @returns - hexcode of color in the given opacity
 */

const getAlphaHex = (hex: string, alpha: number): string => {
  let alphaValue = Math.floor(Number.parseFloat(`-0.${alpha}`) * 255)
    .toString(16)
    .slice(1);

  if (alpha < 6) {
    alphaValue = '0' + alphaValue;
  }

  return hex + alphaValue;
};

export default getAlphaHex;
