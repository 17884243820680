import type { FormData } from '@common/types';

const updateFormDataBeforeSubmit = (formData: FormData): FormData => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const detectedDocType = formData['detectedDocType'].at(-1).text;

  const containerTableData = formData['tables'].find(
    (data) =>
      data?.metadata?.title === 'containerTable' &&
      data?.metadata?.tableType === 'virtual'
  );
  // containerCount form data
  const containerCountData = formData['containerCount'];
  if (detectedDocType === 'Import Order' && containerTableData) {
    const userDataIndex = containerCountData.findIndex(
      (data) => data.source === 'user'
    );
    const newData = {
      page: [[]],
      docID: [],
      text: containerTableData?.summary
        ? (containerTableData.summary.length - 1).toString()
        : '1',
      source: 'user',
    };
    if (userDataIndex === -1) {
      containerCountData.push(newData);
    } else {
      containerCountData[userDataIndex] = newData;
    }
    formData['containerCount'] = containerCountData;
  }

  return formData;
};

export default updateFormDataBeforeSubmit;
