import {
  S3Client,
  GetObjectCommand,
  GetObjectCommandInput,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

const getPresignedURLsFromS3Keys = async (
  Bucket: string,
  credentials: {
    accessKeyId: string;
    secretAccessKey: string;
    expiration: Date;
    sessionToken: string;
  },
  region: string,
  s3Keys: string[],
  // added just for now should be properly implemented later on
  customObjectParams?: Partial<GetObjectCommandInput>
) => {
  if (!s3Keys || s3Keys.length === 0) {
    return [];
  }

  const client = new S3Client({
    credentials,
    region,
  });

  for (const [idx, s3Key] of s3Keys.entries()) {
    s3Keys[idx] = s3Key.trim();
  }

  const expiryTime = Number.parseInt(
    ((process.env.NEXT_PUBLIC_PRESIGN_FETCH_EXPIRY as string) || '1800').trim()
  );

  const urlFetchPromArray = [];

  for (const Key of s3Keys) {
    const getObjectParams: GetObjectCommandInput = {
      Bucket,
      Key,
      ...customObjectParams,
    };
    const command = new GetObjectCommand(getObjectParams);
    urlFetchPromArray.push(
      getSignedUrl(client, command, {
        expiresIn: expiryTime,
      })
    );
  }

  const promisesSettledResult = await Promise.allSettled(urlFetchPromArray);

  const URLs: string[] = promisesSettledResult.map(
    (promResult: {
      status: 'fulfilled' | 'rejected';
      value?: string;
      reason?: any;
    }) => {
      const { status, value } = promResult;
      if (status === 'fulfilled') {
        return value ?? '';
      }
      return '';
    }
  );

  return URLs;
};

export default getPresignedURLsFromS3Keys;
