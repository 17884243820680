import styled from '@emotion/styled';

const FormInputOption = styled.div`
  padding: 8px 10px 5px 10px;
  color: ${(props) => props.theme.color.gray500};
  border-left: 2px solid transparent;
  font-size: 1em;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;

  * {
    width: 100%;
    min-width: 0;
  }

  &:hover {
    background: ${(props) => props.theme.color.primary200};
    // border-left: 2px solid ${(props) => props.theme.color.accent700};
    color: ${(props) => props.theme.color.gray50};
  }
  &.active {
    border-left: 2px solid ${(props) => props.theme.color.accent700};
    color: ${(props) => props.theme.color.accent700};

    .secondary-option-text {
      color: ${(props) => props.theme.color.gray50};
    }
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export { FormInputOption };

export { default as FormInput } from './FormInput';
