import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import type { Document } from '@apps/common/types';

const downloadDocuments = async (jobID: string, documents: Document[]) => {
  // Directly download without creating a zip if a single document is present
  if (documents.length === 1) {
    const doc = documents[0];
    // Fetch the file
    const res = await fetch(doc.originalFileURL);
    // Skip the file if not a success response
    if (!res.ok) {
      console.error('Could not fetch the file!');
      return;
    }
    // Convert response to blob
    const fileBlob = await res.blob();
    // Download
    saveAs(fileBlob, doc.docName);
    return;
  }

  // Zip instance
  const zip = new JSZip();
  // Create folder named as the jobID for adding fetched files to
  const jobZip = zip.folder(jobID);

  // Fetch and add files to the zip
  const downloadFiles = async (documents: Document[]) => {
    for (const doc of documents) {
      // Fetch the file
      const res = await fetch(doc.originalFileURL);
      // Skip the file if not a success response
      if (!res.ok) continue;
      // Convert response to blob and add to the zip instance
      const fileBlob = await res.blob();
      jobZip?.file(doc.docName, fileBlob);
    }
  };

  // Download files
  await downloadFiles(documents);

  // Generate and download the zip file
  zip
    .generateAsync({ type: 'blob' })
    .then((content: any) => saveAs(content, jobID));
};

export default downloadDocuments;
