import axios, { AxiosError, AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
let previousAccessToken = '';
let previousExp: number;

const getAccessTokenWeb = (): Promise<string> => {
  return new Promise((resolve) => {
    if (
      previousAccessToken &&
      previousExp &&
      previousExp > Number((Date.now() / 1000).toFixed(0)) + 10
    ) {
      resolve(previousAccessToken);
    } else {
      const accessToken = process.env.NEXT_PUBLIC_JWT_TOKEN as string;
      if (accessToken === undefined || accessToken.length === 0) {
        makeRequest().then((token) => {
          resolve(token);
        });
      } else {
        previousExp = (jwtDecode(accessToken) as any).exp;
        previousAccessToken = accessToken;
        resolve(accessToken);
      }
    }
  });
};

let isRequestPending = false;

const makeRequest = (): Promise<string> => {
  const NEXT_PUBLIC_BACKEND_HTTP_URL = process.env
    .NEXT_PUBLIC_BACKEND_HTTP_URL as string;
  return new Promise((resolve, reject) => {
    if (isRequestPending === true) {
      waitForRequest(resolve);
    } else {
      isRequestPending = true;
      axios
        .get(NEXT_PUBLIC_BACKEND_HTTP_URL + '/dashboard/auth/v1/refreshtoken', {
          withCredentials: true,
        })
        .then((res: AxiosResponse) => {
          previousAccessToken = res.data.JWT_access;
          previousExp = (jwtDecode(res.data.JWT_access) as any).exp;
          resolve(previousAccessToken);
          isRequestPending = false;
        })
        .catch((error: AxiosError) => {
          isRequestPending = false;
          if (error.response && error.response.status === 401) {
            window.location.href =
              NEXT_PUBLIC_BACKEND_HTTP_URL + '/connect/koireader';
          } else {
            reject(error);
          }
        });
    }
  });
};

const waitForRequest = (resolve: (accessToken: string) => void) => {
  setTimeout(() => {
    if (isRequestPending === true) {
      waitForRequest(resolve);
    } else {
      resolve(previousAccessToken);
    }
  }, 500);
};

export default getAccessTokenWeb;
