import type { FormFieldGroup } from '../../types';

const getErrorCount = (
  formGroup: FormFieldGroup,
  formErrors: Record<string, any>
) => {
  let errorCount = 0;
  if (formGroup.name === 'tables') {
    if (formErrors['tables']?.['container']) {
      for (const row of formErrors['tables']['container']) {
        errorCount += Object.keys(row).length;
      }
    }
  } else {
    for (const field of formGroup.fields) {
      if (formErrors[field.name]) {
        errorCount++;
      }
      if (field.type === 'address') {
        errorCount += Object.keys(formErrors).filter((key) =>
          key.startsWith(`${field.name}.`)
        ).length;
      }
    }
  }
  return errorCount;
};

export default getErrorCount;
