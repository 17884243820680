import { useMutation } from '@apollo/client';
import { compare } from 'fast-json-patch';
import { saveAs } from 'file-saver';

import { wickedAppVar, wickedApiVar } from 'src/apollo/reactiveVars';

import { JOB_SAVE_OR_APPROVE } from '../apollo/queries';
import updateFormDataBeforeSubmit from '../utils/Form/updateFormDataBeforeSubmit';

import type { Job, JobConfig } from '../types';

interface Options {
  saveOrApprove: 'save' | 'finalApprove';
  job: Job;
  jobConfig: JobConfig | null;
  jobTypeID?: string;
  data?: any;
  currentRev: number;
}

interface JobSaveOrApproveResponse {
  jobID: string;
  rev: string;
  message: string;
  testerCSV?: string;
}

const useJobSaveOrApprove = (errorCallback: any, successCallback: any) => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Query
  const [mutate, { loading }] = useMutation(JOB_SAVE_OR_APPROVE, {
    onCompleted: ({
      saveOrApprove,
    }: {
      saveOrApprove: JobSaveOrApproveResponse;
    }) => {
      successCallback(saveOrApprove.message);

      // Exit if testerCSV data is not available in the received data
      if (!saveOrApprove.testerCSV) return;

      // Parse to JSON
      const csvData: string[][] =
        typeof saveOrApprove.testerCSV === 'string'
          ? JSON.parse(saveOrApprove.testerCSV)
          : saveOrApprove.testerCSV;

      // Generate CSV string
      const csvString = csvData
        .map((row) => row.toString())
        .map((row) => `${row}\n`)
        .join('');

      // Create CSV file
      const csvFile = new File([csvString], `${saveOrApprove.jobID}.csv`, {
        type: 'text/csv;charset=utf-8',
      });

      // Save CSV file
      saveAs(csvFile);
    },
    onError: (error) => {
      errorCallback(error.message);
    },
  });
  // Func
  const saveOrApprove = ({
    saveOrApprove,
    job,
    jobTypeID,
    jobConfig,
    data,
    currentRev,
  }: Options) => {
    const olddata = data && JSON.parse(job.data.currentData);
    const newData = data && {
      data: [updateFormDataBeforeSubmit(data)],
      docDetails: olddata.docDetails,
    };
    const editedPatch = data && compare(olddata, newData);

    // Add dataUpdateParams only if data is present
    const dataUpdateParams = data && {
      editedDataComplete: JSON.stringify(newData),
      editJSONPatch: JSON.stringify(editedPatch),
      customerConfig: JSON.stringify(jobConfig),
    };

    console.log('SaveOrApprove', {
      jobID: job.jobID,
      saveOrApprove,
      currentRev,
      updatedJobTypeID: jobTypeID,
      dataUpdateParams: dataUpdateParams,
      wickedApp,
      wickedApi,
    });

    mutate({
      variables: {
        jobID: job.jobID,
        saveOrApprove,
        currentRev,
        updatedJobTypeID: jobTypeID,
        dataUpdateParams: dataUpdateParams,
        wickedApp,
        wickedApi,
      },
    });
  };

  return {
    loading,
    saveOrApprove,
  };
};

export default useJobSaveOrApprove;
