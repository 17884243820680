import React from 'react';

import { useThrottledCallback } from 'use-debounce';

const useSize = (element: any): DOMRectReadOnly | undefined => {
  const current = element && element.current;
  const observer = React.useRef<any>(null);
  const [size, setSize] = React.useState<DOMRectReadOnly>();

  const observe = () => {
    if (element && element.current && observer.current) {
      observer.current.observe(element.current);
    }
  };

  // Update size only once per 500ms
  const updateSize = useThrottledCallback(
    (contentRect) => setSize(contentRect),
    500
  );

  React.useEffect(() => {
    if (observer && observer.current && current) {
      observer.current.unobserve(current);
    }
    observer.current = new ResizeObserver(([entry]) =>
      updateSize(entry.contentRect)
    );
    observe();

    return () => {
      if (observer.current && current) {
        observer.current.unobserve(current);
      }
    };
  }, [current]);

  return size;
};

export default useSize;
