const checkTableErrors = (tableErrors: Record<string, any>) => {
  for (const table of Object.keys(tableErrors)) {
    const isTableError = tableErrors[table].some(
      (row: any) => row && Object.keys(row).length > 0
    );
    if (isTableError) return true;
  }
  return false;
};

const scrollErrorFieldIntoView = (
  errors: Record<string, any>,
  fieldLayoutSequence: any
) => {
  for (const group of fieldLayoutSequence) {
    if (group[0].groupName === 'tables' && checkTableErrors(errors.tables)) {
      const errorFieldRef = document.querySelector('#tables');
      errorFieldRef?.scrollIntoView({ behavior: 'smooth' });
      break;
    }

    const groupHasError = group[1].some((field: any) => {
      const fieldHasError = Object.keys(errors).includes(field.fieldName);
      if (fieldHasError) {
        const errorFieldRef = document.querySelector(`#${field.fieldName}`);
        errorFieldRef?.scrollIntoView({ behavior: 'smooth' });
        return true;
      }
    });

    if (groupHasError) break;
  }
};

export default scrollErrorFieldIntoView;
