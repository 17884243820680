import { useTheme } from '@emotion/react';
import { Tooltip as AntdTooltip, TooltipProps } from 'antd';

const Tooltip = (props: TooltipProps) => {
  const theme = useTheme();
  return (
    <AntdTooltip
      title={props.title}
      color={props.color || theme.color.primary200}
      mouseEnterDelay={props.mouseEnterDelay || 0.3}
      trigger={props.trigger || 'hover'}
      placement={props.placement || 'top'}
      {...props}
    >
      {props.children}
    </AntdTooltip>
  );
};

export default Tooltip;
