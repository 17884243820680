import { docDigitizeUtils } from '@koireader/javascript-utilities-package';

import { wickedAppVar } from 'src/apollo/reactiveVars';
import { Events } from 'src/global/constants';

import eventBus from './eventBus';
import getPresignedURLsFromS3Keys from './getPresignedURLsFromS3Keys';

const getUnixTime = () => Number((Date.now() / 1000).toFixed(0));

interface ImageCredentials {
  region: string;
  bucket: string;
  credentials: {
    AccessKeyId: string;
    SecretAccessKey: string;
    SessionToken: string;
    Expiration: Date;
  };
}

const documentImageStore: Record<string, { imageUrl: string; iat: number }> =
  {};

const getDocumentImage = async (
  imageCredentials: ImageCredentials,
  docID: string,
  docName: string,
  page: number,
  type: 'thumbnail' | 'display'
) => {
  // Get S3 Keys
  const s3Keys =
    type === 'display'
      ? docDigitizeUtils.getS3KeysOfDocumentDisplayImages(
          docID,
          wickedAppVar(),
          [page],
          String(docName.split('.').pop()).toLowerCase()
        )
      : docDigitizeUtils.getS3KeysOfThumbnails(docID, wickedAppVar(), [page]);

  // Check if image URL has expired
  const imageStored = documentImageStore[s3Keys[0]];
  if (imageStored) {
    const expiryTime = Number.parseInt(
      (
        (process.env.NEXT_PUBLIC_PRESIGN_FETCH_EXPIRY as string) || '1800'
      ).trim()
    );
    // Check if the image URL would NOT expire in 20s from now
    if (imageStored.iat + expiryTime > getUnixTime() + 20) {
      return imageStored.imageUrl;
    }
  }

  // Check if the image creds are going to expire in 20s from now
  if (
    getUnixTime() + 20 >
    new Date(
      Number.parseInt(imageCredentials.credentials.Expiration as any)
    ).getTime() /
      1000
  ) {
    eventBus.dispatch(Events.imageCredentialsExpired, {});
    console.log('Image Credentials Expired!');
    return '';
  }

  // Create new Image URL
  const imageUrls = await getPresignedURLsFromS3Keys(
    imageCredentials.bucket,
    {
      accessKeyId: imageCredentials.credentials.AccessKeyId,
      secretAccessKey: imageCredentials.credentials.SecretAccessKey,
      expiration: imageCredentials.credentials.Expiration,
      sessionToken: imageCredentials.credentials.SessionToken,
    },
    imageCredentials.region,
    s3Keys
  );
  // Update Image in Store
  documentImageStore[s3Keys[0]] = {
    imageUrl: imageUrls[0],
    iat: getUnixTime(),
  };
  return imageUrls[0];
};

export default getDocumentImage;
