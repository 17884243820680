import { ApolloError, useQuery } from '@apollo/client';

import { wickedAppVar, wickedApiVar } from 'src/apollo/reactiveVars';

import { GET_UNASSIGNED_DOCUMENTS_BY_API } from '../apollo/queries';

import type { Document } from './../types';

const useUnassignedDocuments = (): any => {
  // Get Wicked App and Api
  const wickedApp = wickedAppVar();
  const wickedApi = wickedApiVar();
  // Query
  const {
    data: documentsData,
    loading,
    refetch,
  } = useQuery<{ getUnassignedDocumentsByAPI: Document[] }>(
    GET_UNASSIGNED_DOCUMENTS_BY_API,
    {
      variables: {
        wickedApp,
        wickedApi,
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      onError: (error: ApolloError) => {
        console.log(error.message);
      },
    }
  );
  const documents = loading ? [] : documentsData?.getUnassignedDocumentsByAPI;
  return {
    documents,
    loading,
    refetch,
  };
};

export default useUnassignedDocuments;
