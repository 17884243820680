import upperCase from 'lodash/upperCase';

import { DOCUMENT_COLORS } from 'src/global/constants';

import type { Job, DocumentMetaData } from '../../types';

const prepareDocumentMetaData = (job: Job) => {
  const data: Record<string, DocumentMetaData> = {};
  if (Array.isArray(job.documents)) {
    for (const i in job.documents) {
      const color =
        Number.parseInt(i) > DOCUMENT_COLORS.length - 1
          ? '#ffffff'
          : DOCUMENT_COLORS[i];
      const docName = job.documents[i].docName;
      const docType = upperCase(job.documents[i].docType);
      data[job.documents[i].docID] = {
        color,
        docName,
        docType,
      };
    }
  }
  return data;
};

export default prepareDocumentMetaData;
