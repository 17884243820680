import { notification } from 'antd';

import ErrorIcon from '@assets/Notifications/Sticky/ErrorIcon.svg';
import InfoIcon from '@assets/Notifications/Sticky/InfoIcon.svg';
import SuccessIcon from '@assets/Notifications/Sticky/SuccessIcon.svg';
import WarningIcon from '@assets/Notifications/Sticky/WarningIcon.svg';

interface Props {
  type: 'success' | 'error' | 'warning' | 'info';
  description: any;
  title: any;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  key?: string;
  onClick?: void;
  onClose?: void;
  // duration?: number;
  // icon?: React.ReactNode;
}

const StickyNotification = (props: Props): any => {
  let dynamicIcon: React.ReactNode, dynamicClassname: string;

  switch (props.type) {
    case 'success': {
      dynamicIcon = <SuccessIcon />;
      dynamicClassname = 'success-sticky-notification';

      break;
    }
    case 'error': {
      dynamicIcon = <ErrorIcon />;
      dynamicClassname = 'error-sticky-notification';

      break;
    }
    case 'warning': {
      dynamicIcon = <WarningIcon />;
      dynamicClassname = 'warning-sticky-notification';

      break;
    }
    default: {
      dynamicIcon = <InfoIcon />;
      dynamicClassname = 'info-sticky-notification';
    }
  }

  const options = {
    message: props.title,
    description: props.description,
    duration: 4,
    bottom: 90,
    className: dynamicClassname,
    icon: dynamicIcon,
    placement: props.placement || 'bottomLeft',
    style: {
      width: '48rem',
    },
  };

  // Global config. maxCount is not supported when passing options directly
  notification.config({
    maxCount: 3,
  });

  props.key ? Object.assign(options, { key: props.key }) : null;
  props.onClick ? Object.assign(options, { onClick: props.onClick }) : null;
  props.onClose ? Object.assign(options, { onClose: props.onClose }) : null;

  return notification[props.type](options);
};

export default StickyNotification;
