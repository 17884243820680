import { getMandatoryFields } from '@koireader/javascript-utilities-package';

import type { FormFieldGroup, FormData, JobConfig } from '../../types';

const updateMandatoryFields = (
  jobConfig: JobConfig,
  formFieldGroups: FormFieldGroup[],
  formData: FormData
): FormFieldGroup[] => {
  const UILayout = jobConfig.UILayout;
  const fieldLayoutSequence = jobConfig.fieldLayoutSequence;

  // Prepare Complex Data
  const complexFormData = {
    data: [formData],
  };

  // Mandatory Field List
  const mandatoryFieldsResults = getMandatoryFields(
    complexFormData,
    UILayout,
    fieldLayoutSequence,
    true
  );
  const mandatoryFields = mandatoryFieldsResults.complex;
  for (const group of formFieldGroups) {
    for (const field of group.fields) {
      field.mandatory = Object.keys(mandatoryFields).includes(field.name);
      if (field.nestedFields.length > 0) {
        for (const nestedField of field.nestedFields) {
          const details = nestedField.name.split('.');
          nestedField.mandatory =
            Boolean(mandatoryFields[field.name]?.[details[0]]?.[details[1]]) ||
            false;
        }
      }
    }
  }
  return formFieldGroups;
};

export default updateMandatoryFields;
