import '../scripts/wdyr';
import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';

import '@koireader/react-image-gallery/build/index.css';
import '../global/styles/antd-custom.less';
import ErrorBoundary from '@components/elements/ErrorBoundary';
import Loader from '@components/elements/Loader';
import ModalContainer from '@components/elements/ModalContainer';
import { useApolloClient } from '@hooks';
import { initGA, logEvent, logPageView } from '@utils/analytics';
import wickedInit from '@utils/wickedInit';

import MainLayout from '../global/components/layouts/mainLayout';
import { AntdGlobalStyles } from '../global/styles/AntdGlobalStyles';

import type { NextWebVitalsMetric } from 'next/app';

function App({ Component, pageProps, err }: any): JSX.Element {
  const router = useRouter();

  // Loading state
  const [loading, setLoading] = React.useState(false);

  // Apollo client
  const { client } = useApolloClient();

  React.useEffect(() => {
    // Initialize GA
    initGA();
    // `routeChangeComplete` won't run for the first page load unless the query string is
    // hydrated later on, so here we log a page view if this is the first render and
    // there's no query string
    if (!router.asPath.includes('?')) {
      logPageView();
    }

    setLoading(true);
    wickedInit().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Listen for page changes after a navigation or when the query changes
    router.events.on('routeChangeComplete', logPageView);
    return () => {
      router.events.off('routeChangeComplete', logPageView);
    };
  }, [router.events]);

  return (
    // Error boundary
    <ErrorBoundary fallback={<p>App Level Error</p>}>
      {/* Global styles required for AntD */}
      <AntdGlobalStyles />
      {/* Layout */}
      {/**
       * Note:  Apollo client is conditionally passed to the pages to support 'Guest Routes'
       *        Guest routes are routes/pages of our application which we display to any unauthenticated user visiting the dashboard url
       *        As of now, '/login' is the only route/page avcailable as a guest route
       */}
      <MainLayout>
        {loading ? (
          // Loader
          <Loader loading={loading} />
        ) : // eslint-disable-next-line unicorn/no-nested-ternary
        client ? (
          /**
           * Note:  Apollo client is conditionally passed to the pages to support 'Guest Routes'
           *        Guest routes are routes/pages of our application which we display to any unauthenticated user visiting the dashboard url
           *        As of now, '/login' is the only route/page avcailable as a guest route
           */

          // Page component with apollo client
          <ApolloProvider client={client}>
            <ModalContainer>
              <Component {...pageProps} err={err} />
            </ModalContainer>
          </ApolloProvider>
        ) : (
          // Page component without apollo client
          <ModalContainer>
            <Component {...pageProps} err={err} />
          </ModalContainer>
        )}
      </MainLayout>
    </ErrorBoundary>
  );
}

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  if (String(process.env.NEXT_PUBLIC_ANALYTICS_ID).length > 0) {
    logEvent({
      category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      action: name,
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      label: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
    });
  }
}

export default appWithTranslation(App);
