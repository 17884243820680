import { ApolloError, useLazyQuery } from '@apollo/client';

import { GET_ENUM_VALUES } from '../apollo/queries';

import type { EnumValuesData } from '../types';
import type { WickedApi } from 'src/global/types';

interface EnumValuesVariables {
  wickedApp: string;
  wickedApi: WickedApi;
}

const useEnumValues = (): {
  fetchEnumValues: (variables: EnumValuesVariables) => void;
  enumValues: EnumValuesData;
  loading: boolean;
} => {
  // Get Enum By API Query
  const [getEnumValues, { data: enumData, loading }] = useLazyQuery<{
    getEnumValues: any;
  }>(GET_ENUM_VALUES, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const fetchEnumValues = ({ wickedApp, wickedApi }: EnumValuesVariables) => {
    getEnumValues({
      variables: {
        wickedApp,
        wickedApi,
      },
    });
  };

  // Enum Values
  const enumValues = loading
    ? null
    : JSON.parse(enumData?.getEnumValues || null);

  return {
    fetchEnumValues,
    enumValues,
    loading,
  };
};

export default useEnumValues;
