const capitalize = (text: string): string =>
  text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');

const getFormattedStatus = (status: string): string => {
  const plainStatusText = status.replaceAll('_', ' ');
  return capitalize(plainStatusText);
};

export default getFormattedStatus;
