import AppointmentDetailsIcon from '@assets/Icons/Form/AppointmentDetails.svg';
import BasicInformationIcon from '@assets/Icons/Form/BasicInformationN.svg';
import ChargeablesIcon from '@assets/Icons/Form/Chargeables.svg';
import ChassisDetailsIcon from '@assets/Icons/Form/ChassisDetails.svg';
import ContainerDetailsIcon from '@assets/Icons/Form/ContainerDetails.svg';
import DatesIcon from '@assets/Icons/Form/Dates.svg';
import DeliveryAddressIcon from '@assets/Icons/Form/DeliveryAddress.svg';
import LocationIcon from '@assets/Icons/Form/Location.svg';
import RoutingIcon from '@assets/Icons/Form/Routing.svg';
import ShippingInformationIcon from '@assets/Icons/Form/ShippingInformation.svg';
import TableIcon from '@assets/Icons/Form/TablesHeader.svg';
import { FormGroupLabels } from 'src/global/constants';

// import OriginAddressIcon from '@assets/Icons/Form/OriginAddress.svg';
// import ReturnAddressIcon from '@assets/Icons/Form/ReturnAddress.svg';
// import CustomerIcon from '@assets/Icons/Form/Customer.svg';
// import HaulIcon from '@assets/Icons/HaulDetails.svg';

const getFormGroupIcon = (groupLabel: string): JSX.Element => {
  switch (groupLabel) {
    case FormGroupLabels.basicInformation: {
      return <BasicInformationIcon />;
    }
    case FormGroupLabels.containerDetails: {
      return <ContainerDetailsIcon />;
    }
    case FormGroupLabels.tables:
    case FormGroupLabels.containerTable: {
      return <TableIcon />;
    }
    case FormGroupLabels.addresses: {
      return <DeliveryAddressIcon />;
    }
    case FormGroupLabels.events: {
      return <DatesIcon />;
    }
    case FormGroupLabels.chassisDetails: {
      return <ChassisDetailsIcon />;
    }
    case FormGroupLabels.location: {
      return <LocationIcon />;
    }
    case FormGroupLabels.routing: {
      return <RoutingIcon />;
    }
    case FormGroupLabels.chargeables: {
      return <ChargeablesIcon />;
    }
    case FormGroupLabels.shippingInformation: {
      return <ShippingInformationIcon />;
    }
    case FormGroupLabels.appointmentDetails: {
      return <AppointmentDetailsIcon />;
    }

    default: {
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path d="M0 0h24v24H0z"></path>
            <path d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"></path>
          </g>
        </svg>
      );
    }
  }
};

export default getFormGroupIcon;
