import { gql } from '@apollo/client';

/* Query */
// Get Api App Config
export const GET_APP_API_CONFIG = gql`
  query getAppApiConfig($wickedApp: String!, $wickedApi: String!) {
    getAppApiConfig(wickedApp: $wickedApp, wickedApi: $wickedApi)
  }
`;

// Get Enum Values
export const GET_ENUM_VALUES = gql`
  query getEnumValues($wickedApp: String!, $wickedApi: String!) {
    getEnumValues(wickedApp: $wickedApp, wickedApi: $wickedApi)
  }
`;

// Get Jobs by API
export const GET_JOBS_BY_API = gql`
  query getJobsByApi(
    $jobIDString: String
    $status: [String]
    $type: [String]
    $limit: Int
    $startAfter: Int
    $dateTypeForFilter: DateTypeEnum
    $dateRangeForFilter: [String]
    $wickedApp: String!
    $wickedApi: String!
  ) {
    getJobsByAPI(
      wickedApp: $wickedApp
      wickedApi: $wickedApi
      jobIDString: $jobIDString
      status: $status
      type: $type
      limit: $limit
      startAfter: $startAfter
      dateTypeForFilter: $dateTypeForFilter
      dateRangeForFilter: $dateRangeForFilter
    ) {
      jobID
      jobType {
        jobTypeDisplayText
        jobTypeID
      }
      createdAt
      updatedAt
      users {
        email
        id
      }
      status
      documents {
        docID
        docType
        docName
        totalPages
      }
      data {
        latestRev
      }
      metadata {
        bookmark
      }
    }
  }
`;

// Get Jobs by API
export const GET_JOB_COUNT_BY_API = gql`
  query getJobCountByAPI(
    $jobIDString: String
    $status: [String]
    $type: [String]
    $dateTypeForFilter: DateTypeEnum
    $dateRangeForFilter: [String]
    $wickedApp: String!
    $wickedApi: String!
  ) {
    getJobCountByAPI(
      wickedApp: $wickedApp
      wickedApi: $wickedApi
      jobIDString: $jobIDString
      status: $status
      type: $type
      dateTypeForFilter: $dateTypeForFilter
      dateRangeForFilter: $dateRangeForFilter
    )
  }
`;

export const GET_JOBS_BY_IDS = gql`
  query getJobsByID(
    $jobIDs: [String!]!
    $wickedApp: String!
    $wickedApi: String!
  ) {
    getJobsByID(jobIDs: $jobIDs, wickedApp: $wickedApp, wickedApi: $wickedApi) {
      jobID
      jobType {
        jobTypeDisplayText
        jobTypeID
      }
      createdAt
      updatedAt
      users {
        email
        id
      }
      status
      data {
        currentData
        latestRev
      }
      metadata {
        bookmark
      }
      documents {
        docID
        docType
        docName
        totalPages
      }
    }
  }
`;
// Get pre-signed URLs in document data
export const GET_JOB_DOCUMENTS = gql`
  query getJobsByID(
    $jobIDs: [String!]!
    $wickedApp: String!
    $wickedApi: String!
  ) {
    getJobsByID(jobIDs: $jobIDs, wickedApp: $wickedApp, wickedApi: $wickedApi) {
      documents {
        docID
        docName
        originalFileURL
      }
    }
  }
`;

export const GET_JOBS_BY_CURSOR = gql`
  query getJobsByCursor(
    $jobID: String!
    $direction: jobCursorDirection!
    $limit: Int
    $wickedApp: String!
    $wickedApi: String!
  ) {
    getJobsByCursor(
      wickedApp: $wickedApp
      wickedApi: $wickedApi
      jobID: $jobID
      direction: $direction
      limit: $limit
    ) {
      jobID
      direction
      jobs {
        jobID
        jobType {
          jobTypeDisplayText
          jobTypeID
        }
        createdAt
        updatedAt
        users {
          email
          id
        }
        status
        documents {
          docID
          docType
          docName
          totalPages
        }
        data {
          latestRev
        }
        metadata {
          bookmark
        }
      }
    }
  }
`;

export const GET_COUNT_BY_STATUS = gql`
  query getCountByStatusOfJobs($wickedApp: String!, $wickedApi: String!) {
    getCountByStatusOfJobs(wickedApp: $wickedApp, wickedApi: $wickedApi)
  }
`;

export const GET_UNASSIGNED_DOCUMENTS_BY_API = gql`
  query getUnassignedDocumentsByAPI($wickedApp: String!, $wickedApi: String!) {
    getUnassignedDocumentsByAPI(wickedApp: $wickedApp, wickedApi: $wickedApi) {
      docID
      docType
      docName
      totalPages
    }
  }
`;

/* Mutation */
// Create Job
export const CREATE_JOB = gql`
  mutation createNewJob(
    $jobType: String!
    $wickedApp: String!
    $wickedApi: String!
  ) {
    createNewJob(
      jobType: $jobType
      wickedApp: $wickedApp
      wickedApi: $wickedApi
    ) {
      jobID
      createdAt
      updatedAt
      status
      documents {
        docID
        docType
        docName
      }
      data {
        latestRev
      }
      metadata {
        bookmark
      }
    }
  }
`;
export const DELETE_JOB = gql`
  mutation deleteJobByID(
    $jobID: String!
    $currentRev: Int!
    $wickedApp: String!
    $wickedApi: String!
  ) {
    deleteJobByID(
      jobID: $jobID
      currentRev: $currentRev
      wickedApp: $wickedApp
      wickedApi: $wickedApi
    )
  }
`;
export const GET_PRESIGNED_POST_SDK = gql`
  query getPresignedPostSDK(
    $fileNameWithExtn: String!
    $fileType: FileTypes!
    $jobID: String
    $wickedApp: String!
    $wickedApi: String!
  ) {
    getPresignedPostSDK(
      fileNameWithExtn: $fileNameWithExtn
      fileType: $fileType
      jobID: $jobID
      wickedApp: $wickedApp
      wickedApi: $wickedApi
    ) {
      id
      url
      fields
    }
  }
`;
export const ADD_DOCUMENT_TO_JOB = gql`
  mutation addDocumentToJob(
    $jobID: String!
    $docID: String!
    $currentRev: Int!
    $wickedApp: String!
    $wickedApi: String!
  ) {
    addDocumentToJob(
      jobID: $jobID
      docID: $docID
      currentRev: $currentRev
      wickedApp: $wickedApp
      wickedApi: $wickedApi
    ) {
      docID
    }
  }
`;

export const JOB_SAVE_OR_APPROVE = gql`
  mutation saveOrApprove(
    $jobID: String!
    $saveOrApprove: saveOrApproveOptions!
    $currentRev: Int!
    $updatedJobTypeID: String
    $dataUpdateParams: DataUpdateParams
    $wickedApp: String!
    $wickedApi: String!
  ) {
    saveOrApprove(
      jobID: $jobID
      saveOrApprove: $saveOrApprove
      currentRev: $currentRev
      updatedJobTypeID: $updatedJobTypeID
      dataUpdateParams: $dataUpdateParams
      wickedApp: $wickedApp
      wickedApi: $wickedApi
    ) {
      jobID
      rev
      message
      testerCSV
    }
  }
`;

export const MODIFY_JOB_METADATA = gql`
  mutation modifyJobMetadata(
    $jobID: String!
    $bookmarkSet: String
    $wickedApp: String!
    $wickedApi: String!
  ) {
    modifyJobMetadata(
      jobID: $jobID
      bookmarkSet: $bookmarkSet
      wickedApp: $wickedApp
      wickedApi: $wickedApi
    ) {
      jobID
      metadata {
        bookmark
      }
    }
  }
`;

/* Subscriptions */
export const DOCUMENT_UPLOAD_RESPONSE = gql`
  subscription documentUploadedResponse(
    $wickedApp: String!
    $wickedApi: String!
  ) {
    documentUploadedResponse(wickedApp: $wickedApp, wickedApi: $wickedApi) {
      docID
      jobID
      message
      type
      error {
        code
        message
      }
    }
  }
`;
