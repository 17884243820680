import Digitized from '@assets/Icons/Home/Digitized.svg';
import NotProcessed from '@assets/Icons/Home/NotProcessed.svg';
import Processed from '@assets/Icons/Home/Processed.svg';
import TMS from '@assets/Icons/Home/TMS.svg';

const getIconForStatus = (status: string) => {
  switch (status) {
    case 'digitized': {
      return <Digitized />;
    }
    case 'in_third_party_process': {
      return <TMS />;
    }
    case 'processed': {
      return <Processed />;
    }
    case 'not_processed': {
      return <NotProcessed />;
    }
    default: {
      return <Digitized />;
    }
  }
};

export default getIconForStatus;
